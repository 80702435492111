import { Box, IconButton, useDisclosure } from "@chakra-ui/react";
import * as React from "react";
import { FaComment } from "react-icons/fa";
import { useAuth } from "utils/auth/auth-utils";
import { createTexts, useTranslation } from "utils/i18n";
import { FeedbackModal } from "./FeedbackModal";

export const Feedback: React.FC = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { t } = useTranslation();
  const { user } = useAuth();
  if (!user) {
    return null;
  }
  return (
    <>
      <Box position="fixed" bottom={6} right={6} width="fit-content">
        <IconButton
          onClick={onOpen}
          colorScheme="yellow"
          variant="solid"
          isRound
          size="lg"
          icon={<FaComment />}
          aria-label={t(texts.label)}
        />
      </Box>
      <FeedbackModal isOpen={isOpen} onDismiss={onClose} />
    </>
  );
};

const texts = createTexts({
  label: {
    no: "Gi oss tilbakemeldinger",
    en: "Give us feedback",
  },
});
