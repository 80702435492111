import {
  Box,
  DarkMode,
  Flex,
  IconButton,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { HorizontalLogo } from "features/design-system/logo/HorizontalLogo";
import { HorizontalLogoWithText } from "features/design-system/logo/HorizontalLogoWithText";
import Link from "next/link";
import * as React from "react";
import { MdMenu } from "react-icons/md";
import { useAuth } from "utils/auth/auth-utils";
import { createTexts, useTranslation } from "utils/i18n";
import { SiteMenu } from "./SiteMenu";

/** The site header shown at the top of most pages */
export const SiteHeader: React.FC = () => {
  const siteHeaderBackground = useColorModeValue("brand.slateBlack", "black");
  const { onToggle, onClose, isOpen } = useDisclosure();
  const { user } = useAuth();
  const Logo = useBreakpointValue([
    HorizontalLogo,
    HorizontalLogoWithText,
  ]) as React.ComponentType;

  const { t } = useTranslation();

  return (
    <Box backgroundColor={siteHeaderBackground} boxShadow="md" color="white">
      <Box marginX="auto" paddingX="1em">
        <Flex
          as="header"
          justifyContent={["space-between"]}
          alignItems="center"
          paddingY={4}
        >
          <Box href={user ? "/oversikt" : "/"} as={Link} width={[100, 160]}>
            <Logo />
          </Box>
          <DarkMode>
            <IconButton
              display={["flex", "flex", "none"]}
              variant="ghost"
              colorScheme="gray"
              justifyContent="center"
              aria-label={t(texts.menuLabel)}
              icon={<MdMenu />}
              fontSize="2em"
              onClick={onToggle}
            />
          </DarkMode>
          <SiteMenu isOpen={isOpen} onClose={onClose} />
        </Flex>
      </Box>
    </Box>
  );
};

const texts = createTexts({
  menuLabel: {
    en: "Menu",
    no: "Meny",
  },
});
