import {
  As,
  Box,
  BoxProps,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  forwardRef,
  IconButton,
  useBreakpointValue,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import { useNavigation } from "features/use-navigation/useNavigation";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";
import { FaLightbulb } from "react-icons/fa";
import { useAuth } from "utils/auth/auth-utils";
import { createTexts, useTranslation } from "utils/i18n";

type SiteMenuProps = {
  isOpen: boolean;
  onClose: () => void;
};
export const SiteMenu: React.FC<SiteMenuProps> = ({ isOpen, onClose }) => {
  const { toggleColorMode } = useColorMode();
  const { t } = useTranslation();
  const color = useColorModeValue("brand.slateBlack", "white");
  const showDrawerNavigation = useBreakpointValue({ base: true, md: false });
  if (showDrawerNavigation) {
    return (
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay>
          <DrawerContent color={color}>
            <DrawerHeader>{t(texts.drawer.heading)}</DrawerHeader>
            <DrawerCloseButton />
            <DrawerBody>
              <Box as="nav">
                <Navigation />
              </Box>
            </DrawerBody>
            <DrawerFooter alignItems="flex-end" marginBottom={[12, 0]}>
              <IconButton
                aria-label={t(texts.drawer.changeThemeButtonLabel)}
                icon={<FaLightbulb />}
                onClick={toggleColorMode}
                variant="outline"
                colorScheme="yellow"
              />
            </DrawerFooter>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    );
  }
  return (
    <>
      <Box display={["none", "none", "block"]}>
        <Flex as="nav" flexWrap="wrap">
          <Navigation />
        </Flex>
      </Box>
    </>
  );
};

const Navigation = () => {
  const { user } = useAuth();
  const { t } = useTranslation();

  const { isLoading: isNavigationLoading, mainNavigation } = useNavigation();

  if (isNavigationLoading) {
    return null;
  }

  const isLoggedOutUser = !user;

  return (
    <>
      {mainNavigation.map((item) => (
        <NavigationLink key={item.url} href={item.url}>
          {item.title}
        </NavigationLink>
      ))}
      {isLoggedOutUser && (
        <>
          <NavigationLink href="/sok-tilgang">
            {t(texts.navigation.applyForAccess)}
          </NavigationLink>
          <NavigationLink href="/logg-inn">
            {t(texts.navigation.login)}
          </NavigationLink>
        </>
      )}
      {user?.userType === "admin" && (
        <NavigationLink href="/admin">
          {t(texts.navigation.admin)}
        </NavigationLink>
      )}
    </>
  );
};

type NavigationLinkProps = BoxProps & {
  href: string;
  children: React.ReactNode;
};
const NavigationLink = forwardRef<NavigationLinkProps, As>(
  ({ href, children, ...rest }, ref) => {
    const { pathname } = useRouter();
    const isActive = pathname.startsWith(href);
    return (
      <NavigationItem
        as={Link}
        href={href}
        ref={ref}
        isActive={isActive}
        {...rest}
      >
        {children}
      </NavigationItem>
    );
  }
);

type NavigationItemProps = BoxProps & { isActive?: boolean };
const NavigationItem = forwardRef<NavigationItemProps, As>(
  ({ children, isActive, ...rest }, ref) => {
    return (
      <Box
        ref={ref}
        display="block"
        paddingY={3}
        paddingX={[3, 3, 6]}
        borderBottom={["1px solid", "1px solid", "none"]}
        borderColor="gray.500"
        borderRadius={["none", "none", "xl"]}
        fontWeight={
          isActive ? ["800", "800", "inherit"] : ["300", "300", "500"]
        }
        color={[
          "inherit",
          "inherit",
          isActive ? "brand.emergencyYellow" : "inherit",
        ]}
        width={["100%", "100%", "fit-content"]}
        textAlign="left"
        transition="all .2s ease-out"
        _hover={{ backgroundColor: "whiteAlpha.300" }}
        _focusVisible={{
          backgroundColor: "whiteAlpha.300",
          outline: "2px solid",
          outlineColor: "brand.emergencyYellow",
        }}
        {...rest}
      >
        {children}
      </Box>
    );
  }
);
NavigationItem.displayName = "NavigationName";

const texts = createTexts({
  drawer: {
    heading: {
      no: "Meny",
      en: "Menu",
    },
    changeThemeButtonLabel: {
      no: "Endre farger",
      en: "Change theme",
    },
  },
  navigation: {
    applyForAccess: {
      no: "Søk tilgang",
      en: "Apply for access",
    },
    login: {
      no: "Logg inn",
      en: "Login",
    },
    admin: {
      no: "👮‍♀️ Admin",
      en: "👮‍♀️ Admin",
    },
  },
});
