import { httpsCallable } from "firebase/functions";
import {
  GetDiscountCodeError,
  GetDiscountCodeResponse,
} from "pages/api/discounts/[discountId]";
import { auth, functions } from "./firebase/client";

/**
 * Regular fetch, but with the Authorization header set to the current user's JWT token
 */
const fetchWithJwt = async (url: string, options: RequestInit = {}) => {
  const jwt = await auth.currentUser?.getIdToken();
  options.headers = {
    ...options.headers,
    ...(jwt ? { Authorization: `Bearer ${jwt}` } : {}),
  };
  return fetch(url, options);
};

/** Gets a valid verification code */
export const getVerificationCode = async (): Promise<{
  verificationCode: string;
}> => {
  const response = await fetchWithJwt("/api/verification-code");
  return response.json();
};

/** Fetches a discount code for a given discount ID */
export const getDiscountCode = async (discountId: string) => {
  const response = await fetchWithJwt(`/api/discounts/${discountId}`);
  const json = await response.json();
  if (response.ok) {
    return json as GetDiscountCodeResponse;
  }
  throw json as GetDiscountCodeError;
};

type SendContactFormProps = {
  email: string;
  text: string;
  currentUrl?: string;
};
/** Send contact form */
export const sendContactForm = async ({
  email,
  text,
  currentUrl,
}: SendContactFormProps) => {
  const response = await fetch("/api/email/contact-form", {
    method: "POST",
    body: JSON.stringify({ email, text, currentUrl }),
    headers: { "Content-Type": "application/json" },
  });
  if (response.ok) {
    return true;
  }
  const body = await response.json();
  throw new Error(body.error);
};

type ReportBadDiscountCodeProps = {
  partnerName: string;
  discountName: string;
  description: string;
};
/** Report a bad discount code */
export const reportBadDiscountCode = async ({
  partnerName,
  discountName,
  description,
}: ReportBadDiscountCodeProps) =>
  fetchWithJwt("/api/email/bad-code-form", {
    method: "POST",
    body: JSON.stringify({ partnerName, discountName, description }),
    headers: { "Content-Type": "application/json" },
  });

/** Sends the nomination to the OPRA team */
export const sendNomination = (nominationForm: Record<string, string>) =>
  fetch("/api/email/nomination-form", {
    method: "POST",
    body: JSON.stringify(nominationForm),
    headers: { "Content-Type": "application/json" },
  });

export function deleteUserAsAdministrator(uid: string) {
  return httpsCallable(functions, "deleteAuthUser")({ uid });
}

type ReferColleaguesProps = {
  referredEmails: string[];
  referrerName: string;
};
export function referColleagues(data: ReferColleaguesProps) {
  return fetchWithJwt("/api/refer", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
}

/** A very simple fetch function that throws on error  */
export const fetcher = async <T>(url: string, options?: RequestInit) => {
  const response = await fetch(url, options);
  const body = await response.json();
  if (response.ok) {
    return body as T;
  }
  throw body;
};
