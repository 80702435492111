import { Box, BoxProps, Flex, useColorModeValue } from "@chakra-ui/react";
import { DefaultHead } from "features/default-head/DefaultHead";
import { Feedback } from "features/feedback/Feedback";
import { SiteFooter } from "features/site-footer/SiteFooter";
import { SiteHeader } from "features/site-header/SiteHeader";
import React from "react";

type DefaultLayoutProps = {
  /** The content of our layout */
  children: React.ReactNode;
  /** Page title, as shown in the tab */
  title: string;
  /** Page description, as shown in search results */
  description: string;
  /** Page keywords, for SEO reasons */
  keywords?: string[];
  /** The width of the container */
  maxWidth?: BoxProps["maxWidth"];
  /** The slugs for a given content page */
  slugs?: { no: string; en: string };
  /** Margin from the header to the content */
  marginTop?: BoxProps["marginTop"];
  /** Horizontal padding of the content */
  paddingX?: BoxProps["paddingX"];
};
export const DefaultLayout: React.FC<DefaultLayoutProps> = ({
  children,
  title,
  description,
  keywords,
  maxWidth = "800px",
  marginTop = 4,
  paddingX = 4,
  slugs,
}) => {
  const bodyBackground = useColorModeValue(
    "brand.foggyWhite",
    "brand.slateBlack"
  );

  const slugsWithoutLocale = slugs
    ? Object.entries(slugs).reduce((acc, [key, value]) => {
        return { ...acc, [key]: value.replace(`/${key}/`, "/") };
      }, {} as typeof slugs)
    : undefined;

  return (
    <Flex
      backgroundColor={bodyBackground}
      minHeight="100vh"
      flexDirection="column"
    >
      <DefaultHead
        title={title}
        description={description}
        keywords={keywords}
      />
      <SiteHeader />
      <Box
        marginX="auto"
        paddingX={paddingX}
        width="100%"
        maxWidth={maxWidth}
        marginTop={marginTop}
        flex="1"
      >
        {children}
      </Box>
      <Feedback />
      <Box marginTop={12}>
        <SiteFooter slugs={slugsWithoutLocale} />
      </Box>
    </Flex>
  );
};
