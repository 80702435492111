import { Box, BoxProps } from "@chakra-ui/react";
import React from "react";
import theme from "../../../utils/theme";

export const HorizontalLogo = (props: BoxProps) => {
  return (
    <Box
      as="svg"
      viewBox="0 0 220 80"
      width="100%"
      fill="none"
      aria-label="OPRA - Operativ rabatt"
      {...props}
    >
      <path
        d="M40 80a40 40 0 1 0 0-80 40 40 0 0 0 0 80Z"
        fill={theme.colors.brand.emergencyYellow}
      />
      <path
        d="M29 66.5c-.3-6.5-8-12.5-8-12.6l-2-1.6L40 9.8l21 42.5-2 1.6c-2.2 1.7-7.8 7.2-8 12.6l-6.3-.1c.2-7 5.4-13 8.4-15.8L40 24.2 26.9 50.6c2.9 2.8 8.2 8.8 8.4 15.8h-6.4Z"
        fill={theme.colors.brand.slateBlack}
      />
      <path
        d="M100 40c0-11 5.5-17 14-17s14 6 14 17-5.5 17-14 17-14-6-14-17Zm22.4 2.6v-5.2c0-6-3.4-9.6-8.4-9.6s-8.4 3.7-8.4 9.6v5.2c0 6 3.4 9.6 8.4 9.6s8.4-3.7 8.4-9.6Zm12.2 13.8V23.6h14c5.9 0 9.4 4 9.4 9.9 0 6-3.5 9.8-9.5 9.8h-8.7v13h-5.2Zm5.2-17.7h8.4a3.8 3.8 0 0 0 4-2.4 5 5 0 0 0 .3-1.7v-2.3a3.8 3.8 0 0 0-2.6-3.9c-.6-.2-1.1-.2-1.7-.1h-8.4v10.4ZM169 56.4h-5.3V23.6h14c5.9 0 9.5 3.8 9.5 9.9 0 4.6-2.2 7.9-6.4 9l7.1 13.9h-5.8l-6.6-13.2h-6.4l-.1 13.2Zm8.4-17.7a3.7 3.7 0 0 0 4-2.4c.2-.6.3-1.1.2-1.7v-2.3a3.8 3.8 0 0 0-2.6-3.9c-.5-.2-1-.2-1.6-.1H169v10.4h8.4Zm37.1 17.7-3-8.9h-12l-2.9 8.9h-5.3l11-32.8h6.6L220 56.4h-5.5Zm-8.9-27.9h-.2L200.7 43h9.6l-4.7-14.5Z"
        fill="currentColor"
      />
    </Box>
  );
};
