import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import * as React from "react";
import { useForm } from "react-hook-form";
import * as api from "utils/api";
import { auth } from "utils/firebase/client";
import { createTexts, useTranslation } from "utils/i18n";

type FormFields = {
  comments: string;
};

type FeedbackModalProps = {
  isOpen: boolean;
  onDismiss: () => void;
};
export const FeedbackModal: React.FC<FeedbackModalProps> = ({
  isOpen,
  onDismiss,
}) => {
  const {
    formState: { errors },
    register,
    handleSubmit,
  } = useForm<FormFields>();
  const createToast = useToast();
  const { t } = useTranslation();
  const email = auth.currentUser?.email;
  const onSubmit = async ({ comments }: FormFields) => {
    let toastId: string | number = 0;

    try {
      await api.sendContactForm({
        email: email ?? "noreply@opra.no",
        text: comments,
        currentUrl: window.location.href,
      });
      toastId = createToast({
        status: "success",
        title: t(texts.toasts.success.title),
        description: t(texts.toasts.success.description),
      });
    } catch (error) {
      if (toastId) {
        createToast.close(toastId);
      }
      createToast({
        status: "error",
        title: t(texts.toasts.error.title),
        description: t(texts.toasts.error.description),
      });
    }

    onDismiss();
  };

  return (
    <Modal onClose={onDismiss} isOpen={isOpen}>
      <ModalOverlay>
        <ModalContent as="form" onSubmit={handleSubmit(onSubmit)}>
          <ModalCloseButton />
          <ModalHeader>{t(texts.heading)}</ModalHeader>
          <ModalBody>
            <Stack>
              <Text>{t(texts.description)}</Text>
              <FormControl isInvalid={!!errors.comments}>
                <FormLabel>{t(texts.message.label)}</FormLabel>
                <Textarea
                  {...register("comments", {
                    required: t(texts.message.validation.required),
                  })}
                />
                <FormErrorMessage>{errors.comments?.message}</FormErrorMessage>
              </FormControl>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button type="submit" variant="solid" colorScheme="yellow">
              {t(texts.sendButtonText)}
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};

const texts = createTexts({
  heading: {
    no: "Kontakt OPRA-teamet",
    en: "Contact the OPRA team",
  },
  description: {
    no: " OPRA drives på fritiden av fulltids ansatte i det operative. Teamet setter pris på alle tilbakemeldinger, og kommer tilbake til deg ved første anledning.",
    en: "OPRA is run in our spare time by full-time employees in operational occupations. The team appreciates all feedback, and will get back to you at the first opportunity.",
  },
  message: {
    label: {
      no: "Hva ønsker du å fortelle?",
      en: "What do you want to tell us?",
    },
    validation: {
      required: {
        no: "Melding mangler",
        en: "Message is missing",
      },
    },
  },
  sendButtonText: {
    no: "Send",
    en: "Send",
  },
  toasts: {
    success: {
      title: {
        no: "Tusen takk!",
        en: "Thank you!",
      },
      description: {
        no: "Tilbakemeldingen er mottatt, og teamet tar en titt på den snarest!",
        en: "The feedback has been received, and the team will take a look at it as soon as possible!",
      },
    },
    error: {
      title: {
        no: "Sending feilet",
        en: "Sending failed",
      },
      description: {
        no: "Noe gikk galt, og vi fikk ikke sendt tilbakemeldingen din. Prøv igjen senere.",
        en: "Something went wrong, and we could not send your feedback. Try again later.",
      },
    },
  },
});
